import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Select from "react-select";

// Utility function to ensure date format
const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${d.getFullYear()}-${month}-${day}`;
};

const ProcessModal = ({
  editingProcess,
  formData,
  handleChange,
  handleSubmit,
  handleOutsideClick,
  projectData,
  technologyData,
  stageData,
  functionData,
  isSubmitting,
}) => {
  const handleTechnologyChange = (selectedOptions) => {
    const selectedTechnologies = selectedOptions
      ? selectedOptions.map((option) => option.value).join(", ")
      : "";
    handleChange({
      target: {
        name: "technology_id",
        value: selectedTechnologies,
      },
    });
  };

  const technologyOptions = technologyData.map((technology) => ({
    value: technology.technology_id,
    label: technology.technology_name,
  }));

  // Format the date values in formData for the date input fields
  const formattedStartDate = formatDate(formData.startDate);
  const formattedEndDate = formatDate(formData.endDate);

  return (
    <div
      id="modal-overlay"
      onClick={handleOutsideClick}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-center m-4">
          <h1 className="text-2xl text-gray-700 font-bold">
            {editingProcess ? "Edit Process" : "Add Process"}
          </h1>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <fieldset className="border p-4 rounded-md">
            <legend className="text-sm font-bold text-gray-700 mb-4">
              Process Details
            </legend>

            <div className="space-y-4">
              {/* Process Id and Project Name */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="user_provided_process_id"
                  >
                    Process Id <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="user_provided_process_id"
                    name="user_provided_process_id"
                    type="text"
                    value={formData.user_provided_process_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Id"
                    required
                    aria-label="Process Id"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="project_id"
                  >
                    Project Name <span className="text-red-600">*</span>
                  </label>
                  <select
                    id="project_id"
                    name="project_id"
                    value={formData.project_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Project Name"
                  >
                    <option value="" disabled>
                      Select Project
                    </option>
                    {projectData.map((project) => (
                      <option
                        key={project.project_id}
                        value={project.project_id}
                      >
                        {project.project_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Process Name and Process Owner */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_name"
                  >
                    Process Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="process_name"
                    name="process_name"
                    type="text"
                    value={formData.process_name}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Name"
                    required
                    aria-label="Process Name"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_owner"
                  >
                    Process Owner <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="process_owner"
                    name="process_owner"
                    type="text"
                    value={formData.process_owner}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Owner"
                    aria-label="Process Owner"
                    required
                  />
                </div>
              </div>

              {/* Technology Name and Function Name */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="technology_name"
                  >
                    Technology Name
                  </label>
                  <Select
                    id="technology_id"
                    name="technology_id"
                    value={technologyOptions.filter((option) =>
                      (formData.technology_id || "")
                        .split(", ")
                        .includes(option.value)
                    )}
                    onChange={handleTechnologyChange}
                    options={technologyOptions}
                    isMulti
                    className="mt-1 block w-full rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Technology Name"
                  />
                </div>

                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="function name"
                  >
                    Function Name
                  </label>
                  <select
                    id="function_id"
                    name="function_id"
                    value={formData.function_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Function Name"
                  >
                    <option value="" disabled>
                      Select Function Name
                    </option>

                    {functionData.map((functions) => (
                      <option
                        key={functions.function_id}
                        value={functions.function_id}
                      >
                        {functions.function_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Process Description, Problem Statement, and Solution */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_description"
                  >
                    Process Description
                  </label>
                  <textarea
                    id="process_description"
                    name="process_description"
                    value={formData.process_description}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Process Description"
                    aria-label="Process Description"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="problem_statement"
                  >
                    Problem Statement
                  </label>
                  <textarea
                    id="problem_statement"
                    name="problem_statement"
                    value={formData.problem_statement}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Problem Statement"
                    aria-label="Problem Statement"
                  />
                </div>
                <div className="col-span-2">
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="solution"
                  >
                    Solution
                  </label>
                  <textarea
                    id="solution"
                    name="solution"
                    value={formData.solution}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Solution"
                    aria-label="Solution"
                  />
                </div>
              </div>

              {/* Annual Benefits, Dates, and Complexity */}

              <div className="flex justify-between">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="startDate"
                  >
                    Start Date <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    value={formattedStartDate}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Start Date"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="endDate"
                  >
                    End Date <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    value={formattedEndDate}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="End Date"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="stage name"
                  >
                    Stage Name <span className="text-red-600">*</span>
                  </label>
                  <select
                    id="stage_id"
                    name="stage_id"
                    value={formData.stage_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Stage Name"
                    required
                  >
                    <option value="" disabled>
                      Select Stage Name
                    </option>

                    {stageData.map((stage) => (
                      <option key={stage.stage_id} value={stage.stage_id}>
                        {stage.stage_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="annualBenefits"
                  >
                    Annual Benefits
                  </label>
                  <input
                    id="annualBenefits"
                    name="annualBenefits"
                    type="number"
                    value={formData.annualBenefits}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter Annual Benefits"
                    aria-label="Annual Benefits"
                  />
                </div>

                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="complexity"
                  >
                    Complexity
                  </label>
                  <select
                    id="complexity"
                    name="complexity"
                    value={formData.complexity}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="Complexity"
                  >
                    <option value="" disabled>
                      Select Complexity
                    </option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="pt-4">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <AiOutlineLoading3Quarters className="animate-spin text-xl mr-2" />
              ) : null}
              {editingProcess ? "Update Process" : "Add Process"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProcessModal;
