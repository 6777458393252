import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { stageEndPoints } from "../../services/apis.js"; // Updated import
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import Loader from "../../utils/Loader.jsx";
import NoDataFound from "../../utils/NoDataFound.jsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const StageDashboard = () => {
  // Updated component name
  const [searchTerm, setSearchTerm] = useState("");
  const [stageData, setStageData] = useState([]); // Updated state variable
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    stage_id: "", // Updated state variable
    stage_name: "", // Updated state variable
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingStage, setEditingStage] = useState(null); // Updated state variable
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  // Optimized filtering logic
  const filteredStages = stageData.filter(
    // Updated variable name
    (
      stage // Updated variable name
    ) =>
      stage.stage_id.toLowerCase().includes(searchTerm.toLowerCase()) || // Updated variable name
      stage.stage_name.toLowerCase().includes(searchTerm.toLowerCase()) // Updated variable name
  );

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingStage(null); // Updated state variable
  };

  const openAddEditModal = (stage) => {
    // Updated variable name
    setFormData({
      stage_id: stage.stage_id || "", // Updated state variable
      stage_name: stage.stage_name, // Updated state variable
    });
    setEditingStage(stage.stage_id ? true : false); // Updated state variable
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (editingStage) {
        // Updated state variable
        await axios.post(stageEndPoints.POST_UPDATE_STAGE, {
          // Updated endpoint
          stage_id: formData.stage_id, // Updated state variable
          stage_name: formData.stage_name, // Updated state variable
        });
        toast.success("Stage updated successfully"); // Updated message
      } else {
        await axios.post(stageEndPoints.POST_CREATE_STAGE, {
          // Updated endpoint
          stage_name: formData.stage_name, // Updated state variable
        });
        toast.success("Stage added successfully"); // Updated message
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    } finally {
      setIsSubmitting(false); // End loader
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchStageData = async (url) => {
    // Updated function name
    setLoading(true);
    try {
      const response = await axios.get(url);
      setStageData(response.data?.data?.data || []); // Updated state variable
    } catch (error) {
      console.error("Error fetching stages:", error); // Updated message
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchStageData(stageEndPoints.GET_ALL_STAGES); // Updated endpoint
  }, [updateUI]);

  const handleStatus = async (stage_id, is_active) => {
    // Updated variable name
    try {
      if (is_active === 1) {
        await axios.post(stageEndPoints.POST_DEACTIVATE_STAGE, {
          // Updated endpoint
          stage_id,
        });
        toast.success("Stage deactivated successfully"); // Updated message
      } else {
        await axios.post(stageEndPoints.POST_ACTIVATE_STAGE, {
          // Updated endpoint
          stage_id,
        });
        toast.success("Stage activated successfully"); // Updated message
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating stage status:", error); // Updated message
      toast.error("Error updating stage status"); // Updated message
    }
  };

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Stages</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Stage Master</h1>{" "}
      {/* Updated heading */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a stage" // Updated placeholder
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a stage" // Updated aria-label
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ stage_name: "" })} // Updated variable name
            className="bg-gray-800 text-white px-8 py-2 font-bold rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            aria-label="Add Stage" // Updated aria-label
          >
            Add Stage {/* Updated button text */}
          </button>
        </div>
      </div>
      {loading ? (
        <Loader /> // Show loader while loading
      ) : filteredStages.length === 0 ? (
        <NoDataFound /> // Show no data found component
      ) : (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer">
                  Serial Number
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer">
                  Stage Name
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredStages.map(
                // Updated variable name
                (
                  stage,
                  index // Updated variable name
                ) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } hover:bg-gray-100`}
                  >
                    <td className="border border-gray-300 p-3 text-gray-700">
                      {stage.stage_id} {/* Updated variable name */}
                    </td>
                    <td className="border border-gray-300 p-3 text-gray-700">
                      {stage.stage_name} {/* Updated variable name */}
                    </td>
                    <td className="border border-gray-300 p-3 text-gray-700">
                      <div className="flex gap-4">
                        <button
                          onClick={() => openAddEditModal(stage)} // Updated variable name
                          className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                        >
                          <FaEdit className="text-indigo-500 text-xl" />
                        </button>

                        <button
                          className="text-sm px-3 py-1 rounded"
                          onClick={
                            () => handleStatus(stage.stage_id, stage.is_active) // Updated variable name
                          }
                        >
                          {stage.is_active === 1 ? (
                            <FaToggleOn className="text-green-500 text-xl" />
                          ) : (
                            <FaToggleOff className="text-red-500 text-xl" />
                          )}{" "}
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {editingStage ? "Edit Stage" : "Add Stage"}{" "}
                {/* Updated heading */}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Stage Name {/* Updated label */}
                </label>
                <input
                  name="stage_name" // Updated name attribute
                  type="text"
                  value={formData.stage_name}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Stage Name"
                  required
                />
              </div>
              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <AiOutlineLoading3Quarters className="animate-spin text-xl mr-2" />
                  ) : null}
                  {editingStage ? "Update Stage" : "Add Stage"}{" "}
                  {/* Updated button text */}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StageDashboard; // Updated export
