import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "./pages/Error";
import PrivateRoute from "./components/layout/PrivateRoute";
import Layout from "./components/layout/Layout";
import Dashboard from "./pages/Dashboard";
import "./App.css";

import axios from "axios";
import ScrollToTop from "./components/common/ScrollToTop";
import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized.jsx";

import ProjectDashboard from "./components/projectMaster/ProjectDashboard.jsx";
import ProcessDashboard from "./components/processMaster/ProcessDashboard.jsx";
import TechnologyDashboard from "./components/TechnologyMaster/TechnologyDashboard.jsx";
import TaskDashboard from "./components/taskMaster/TaskDashboard.jsx";
import TaskAssignmentDashboard from "./components/taskAssignment/TaskAssignmentDashboard.jsx";
import TimesheetDashboard from "./components/timeSheetMaster/TimesheetDashboard.jsx";
// import TransformationLeverDashboard from "./components/TransformationLeverMaster/TransformationLeverDashboard.jsx";
// import ApplicationDashboard from "./components/ApplicationMaster/ApplicationDashboard.jsx";
import StageDashboard from "./components/StageMaster/StageMaster.jsx";
import FunctionDashboard from "./components/FunctionMaster/FunctionMaster.jsx";
import ProjectAssignmentDashboard from "./components/projectAssignment/ProjectAssignmentDashboard.jsx";

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <div>
      <ScrollToTop />

      <Routes>
        {/* Open Routes */}
        <Route path="/" element={<Login />}></Route>

        <Route
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />

          <Route path="projects" element={<ProjectDashboard />} />
          <Route path="task-tracker/processes" element={<ProcessDashboard />} />

          <Route path="technologies" element={<TechnologyDashboard />} />
          <Route path="task-tracker/projects" element={<ProjectDashboard />} />
          <Route
            path="task-tracker/project-assignment"
            element={<ProjectAssignmentDashboard />}
          />
          <Route path="task-tracker/processes" element={<ProcessDashboard />} />

          {/* <Route
            path="task-tracker/application"
            element={<ApplicationDashboard />}
          /> */}
          <Route
            path="settings/technologies"
            element={<TechnologyDashboard />}
          />
          <Route path="settings/stages" element={<StageDashboard />} />

          <Route path="settings/functions" element={<FunctionDashboard />} />
          <Route path="task-tracker/tasks" element={<TaskDashboard />} />

          <Route path="tasks" element={<TaskDashboard />} />
          <Route
            path="task-tracker/task-assignment"
            element={<TaskAssignmentDashboard />}
          />
          <Route
            path="task-tracker/timesheet"
            element={<TimesheetDashboard />}
          />
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};

export default App;
