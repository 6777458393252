import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  functionEndPoints,
  processEndPoints,
  projectEndPoints,
  stageEndPoints,
  technologyEndPoints,
} from "../../services/apis";

import ProcessModal from "./ProcessModal";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import checkRoleAccess from "../shared/CheckRoleAcess";
import {
  FaEdit,
  FaToggleOn,
  FaToggleOff,
  FaSort,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";
import Loader from "../../utils/Loader.jsx";
import NoDataFound from "../../utils/NoDataFound.jsx";

const ProcessDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [processData, setProcessData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [technologyData, setTechnologyData] = useState([]);
  // const [transformationLeverData, setTransformationLeverDataData] = useState(
  //   []
  // );
  // const [applicationData, setApplicationData] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [functionData, setFunctionData] = useState([]);
  const [updateUI, setUpdateUI] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    process_name: "",
    project_id: "",
    process_id: "",
    user_provided_process_id: "",
    process_owner: "",
    process_description: "",
    problem_statement: "",
    solution: "",
    annualBenefits: "",
    startDate: "",
    endDate: "",
    complexity: "",
    technology_id: "",
    stage_id: "",
    function_id: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingProcess, setEditingProcess] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Optimized filtering logic
  const filteredProcesses = processData
    .filter((process) => {
      // const processId = process.process_id
      //   ? process.process_id.toLowerCase()
      //   : "";
      const processName = process.process_name
        ? process.process_name.toLowerCase()
        : "";
      const projectName = process.project_name
        ? process.project_name.toLowerCase()
        : "";

      return (
        // processId.includes(searchTerm.toLowerCase()) ||
        processName.includes(searchTerm.toLowerCase()) ||
        projectName.includes(searchTerm.toLowerCase())
      );
    })
    .sort((a, b) => {
      if (sortConfig.key === null) return 0;

      const aVal = a[sortConfig.key];
      const bVal = b[sortConfig.key];

      // Check if sorting by date field
      if (sortConfig.key === "startDate" || sortConfig.key === "endDate") {
        const dateA = new Date(aVal);
        const dateB = new Date(bVal);
        if (dateA < dateB) return sortConfig.direction === "asc" ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      } else {
        // Handle string sorting
        const aValStr = aVal?.toString().toLowerCase() || "";
        const bValStr = bVal?.toString().toLowerCase() || "";
        if (aValStr < bValStr) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValStr > bValStr) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      }
    });

  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };
  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingProcess(null);
  };

  const openAddEditModal = (process) => {
    setFormData({
      process_name: process.process_name || "",
      project_id: process.project_id || "",
      process_id: process.process_id || "",
      user_provided_process_id: process.user_provided_process_id || "",
      process_owner: process.process_owner || "",
      process_description: process.process_description || "",
      problem_statement: process.problem_statement || "",
      solution: process.solution || "",
      annualBenefits: process.annualBenefits || "",
      startDate: process.startDate || "",
      endDate: process.endDate || "",
      complexity: process.complexity || "",
      technology_id: process.technology_id || "",
      stage_id: process.stage_id || "",
      function_id: process.function_id || "",
    });
    setEditingProcess(process.process_id);
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payload = {
        process_name: formData.process_name,
        project_id: formData.project_id,
        user_provided_process_id: formData.user_provided_process_id,
        process_owner: formData.process_owner,
        process_description: formData.process_description,
        problem_statement: formData.problem_statement,
        solution: formData.solution,
        annualBenefits: formData.annualBenefits,
        startDate: formData.startDate,
        endDate: formData.endDate,
        complexity: formData.complexity,
        technology_id: formData.technology_id,
        stage_id: formData.stage_id,
        function_id: formData.function_id,
      };

      if (editingProcess) {
        await axios.post(processEndPoints.POST_UPDATE_PROCESS, {
          process_id: formData.process_id,
          ...payload,
        });
        toast.success("Process updated successfully");
      } else {
        console.log("Process Payload", payload);
        await axios.post(processEndPoints.POST_CREATE_PROCESS, payload);
        toast.success("Process added successfully");
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    } finally {
      setIsSubmitting(false); // End loader
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProcessData = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      setProcessData(response.data?.data?.data || []);
      console.log(
        "Process Data Successfully fetched",
        response.data?.data?.data
      );
    } catch (error) {
      console.error("Error fetching processes:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchProcessData(processEndPoints.GET_ALL_ACTIVE_PROCESSES);
  }, [updateUI]);

  const useFetchData = (endpoint, setData, updateUI) => {
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(endpoint);
          setData(response.data?.data?.data || []);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }, [endpoint, setData, updateUI]);
  };

  useEffect(() => {
    fetchProcessData(processEndPoints.GET_ALL_PROCESSES);
  }, [updateUI]);

  useFetchData(
    projectEndPoints.GET_ALL_ACTIVE_PROJECTS,
    setProjectData,
    updateUI
  );
  useFetchData(
    technologyEndPoints.GET_ALL_TECHNOLOGIES,
    setTechnologyData,
    updateUI
  );

  // useFetchData(
  //   transformationLeverEndPoints.GET_ALL_ACTIVE_TRANSFORMATIONS,
  //   setTransformationLeverDataData,
  //   updateUI
  // );

  // useFetchData(
  //   applicationEndPoints.GET_ALL_ACTIVE_APPLICATIONS,
  //   setApplicationData,
  //   updateUI
  // );

  useFetchData(stageEndPoints.GET_ALL_ACTIVE_STAGES, setStageData, updateUI);

  useFetchData(
    functionEndPoints.GET_ALL_ACTIVE_FUNCTIONS,
    setFunctionData,
    updateUI
  );

  console.log("===>stage", stageData);

  const handleStatus = async (process_id, is_active) => {
    try {
      if (is_active === 1) {
        await axios.post(processEndPoints.POST_DEACTIVATE_PROCESS, {
          process_id,
        });
        toast.success("Process deactivated successfully");
      } else {
        await axios.post(processEndPoints.POST_ACTIVATE_PROCESS, {
          process_id,
        });
        toast.success("Process activated successfully");
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating process status:", error);
      toast.error("Error updating process status");
    }
  };

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Processes</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Process Dashboard</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a process"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a process"
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ process_name: "" })}
            className="bg-gray-800 text-white px-8 py-2 font-bold rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            aria-label="Add Process"
          >
            Add Process
          </button>
        </div>
      </div>

      {loading ? (
        <Loader /> // Show loader while loading
      ) : filteredProcesses.length === 0 ? (
        <NoDataFound /> // Show no data found component
      ) : (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("process_id")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Process Id</span>
                    {getSortIcon("process_id")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("project_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Project Name</span>
                    {getSortIcon("project_name")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("process_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Process Name</span>
                    {getSortIcon("process_name")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("process_owner")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Process Owner</span>
                    {getSortIcon("process_owner")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("stage")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Stage</span>
                    {getSortIcon("stage")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("startDate")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Start Date</span>
                    {getSortIcon("startDate")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("endDate")}
                >
                  <div className="flex  items-center gap-2">
                    <span> End Date</span>
                    {getSortIcon("endDate")}
                  </div>
                </th>

                <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProcesses.map((process, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {process.user_provided_process_id}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {process.project_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {process.process_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {process.process_owner}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {" "}
                    {process.stage_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(process.startDate).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(process.endDate).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(process)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <FaEdit className="text-indigo-500 text-xl" />
                      </button>

                      <button
                        className="text-sm px-3 py-1 rounded"
                        onClick={() =>
                          handleStatus(process.process_id, process.is_active)
                        }
                      >
                        {process.is_active === 1 ? (
                          <FaToggleOn className="text-green-500 text-xl" />
                        ) : (
                          <FaToggleOff className="text-red-500 text-xl" />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showAddEditModal && (
        <ProcessModal
          editingProcess={editingProcess}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleOutsideClick={handleOutsideClick}
          projectData={projectData}
          technologyData={technologyData}
          stageData={stageData}
          functionData={functionData}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default ProcessDashboard;
