import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  processEndPoints,
  projectEndPoints,
  projectuserroleEndPoints,
  roleEndPoints,
  userEndPoints,
} from "../../services/apis";

import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import checkRoleAccess from "../shared/CheckRoleAcess";
import {
  FaEdit,
  FaToggleOn,
  FaToggleOff,
  FaSort,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";
import ProjectAssignmentModal from "./projectAssignmentModal";
import Loader from "../../utils/Loader.jsx";
import NoDataFound from "../../utils/NoDataFound.jsx";

const ProjectAssignmentDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [processData, setProcessData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [filteredProcessData, setFilteredProcessData] = useState([]);
  const [projectUserRoleData, setProjectUserRoleData] = useState([]);

  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    project_id: "",
    process_id: "",
    startDate: "",
    endDate: "",
    role_id: "",
    user_id: "",
    percentage: "",
    purm_id: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingProcess, setEditingProcess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Optimized filtering logic
  // const lowercasedSearchTerm = searchTerm.toLowerCase();

  // const filteredProcesses = processData.filter((process) => {
  //   const processName = process.process_name?.toLowerCase() || "";
  //   const projectName = process.project_name?.toLowerCase() || "";

  //   return (
  //     processName.includes(lowercasedSearchTerm) ||
  //     projectName.includes(lowercasedSearchTerm)
  //   );
  // });

  const filterProjectUserRoleData = projectUserRoleData
    .filter(
      (projectUserRole) =>
        (projectUserRole.project_name &&
          projectUserRole.project_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (projectUserRole.process_name &&
          projectUserRole.process_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (projectUserRole.user_name &&
          projectUserRole.user_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (projectUserRole.role_name &&
          projectUserRole.role_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      //     ||
      // (projectUserRole.percentage &&
      //   projectUserRole.percentage.includes(searchTerm))
      // (projectUserRole.startDate && projectUserRole.startDate.toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase())) ||
      // (projectUserRole.endDate && projectUserRole.endDate.toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    )
    .sort((a, b) => {
      if (sortConfig.key === null) return 0;

      const aVal = a[sortConfig.key];
      const bVal = b[sortConfig.key];

      // Check if sorting by date field
      if (sortConfig.key === "startDate" || sortConfig.key === "endDate") {
        const dateA = new Date(aVal);
        const dateB = new Date(bVal);
        if (dateA < dateB) return sortConfig.direction === "asc" ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      } else {
        // Handle string sorting
        const aValStr = aVal?.toString().toLowerCase() || "";
        const bValStr = bVal?.toString().toLowerCase() || "";
        if (aValStr < bValStr) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValStr > bValStr) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      }
    });

  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingProcess(null);
  };

  const openAddEditModal = (process) => {
    setFormData({
      project_id: process.project_id || "",
      process_id: process.process_id || "",
      startDate: process.startDate || "",
      endDate: process.endDate || "",
      role_id: process.role_id || "",
      user_id: process.user_id || "",
      percentage: process.percentage || "",
      purm_id: process.purm_id || "",
    });
    setEditingProcess(process.purm_id);
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Filter process data based on selected project
    if (name === "project_id") {
      const filteredProcesses = processData.filter(
        (process) => process.project_id === value
      );
      setFilteredProcessData(filteredProcesses);

      console.log("Process data filtered====>", filteredProcesses);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const payload = {
        project_id: formData.project_id,
        process_id: formData.process_id,
        startDate: formData.startDate,
        endDate: formData.endDate,
        role_id: formData.role_id,
        user_id: formData.user_id,
        percentage: formData.percentage,
        purm_id: formData.purm_id,
      };

      const updatedFormData = { created_by: userId, ...payload };

      if (editingProcess) {
  
        const response = await axios.post(
          projectuserroleEndPoints.POST_UPDATE_PROJECT_USER_ROLE,
          updatedFormData
        );
        toast.success(response?.data?.data?.messageCode);
      } else {
        const response = await axios.post(
          projectuserroleEndPoints.POST_CREATE_PROJECT_USER_ROLE,
          updatedFormData
        );
        toast.success(response?.data?.data?.messageCode);
      }

      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.data?.messageCode || "Error processing request";
      toast.error(errorMessage);
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        projectEndPoints.GET_ALL_ACTIVE_PROJECTS
      );
      setProjectData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchProcessData = async () => {
    try {
      const response = await axios.get(
        processEndPoints.GET_ALL_ACTIVE_PROCESSES
      );
      setProcessData(response.data?.data?.data || []);
      console.log("Process data:", response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(userEndPoints.GET_ALL_ACTIVE_USERS);
      setEmployeeData(response.data?.data?.data || []);
      console.log("Employee data:", response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching employee:", error);
    }
  };

  const fetchRoleData = async () => {
    try {
      const response = await axios.get(roleEndPoints.GET_ALL_ACTIVE_ROLES);
      setRoleData(response.data?.data?.data || []);
      console.log("Role data:", response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchProjectUserRoleData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        projectuserroleEndPoints.GET_ALL_PROJECT_USER_ROLE_DATA
      );
      setProjectUserRoleData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching project user role:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    setUserId(storedUserId);
    fetchProjectData();
    fetchProcessData();
    fetchEmployeeData();
    fetchRoleData();
    fetchProjectUserRoleData();
  }, [updateUI]);

  const handleStatus = async (purm_id, is_active) => {
    try {
      const endpoint =
        is_active === 1
          ? projectuserroleEndPoints.POST_DEACTIVATE_PROJECT_USER_ROLE
          : projectuserroleEndPoints.POST_ACTIVATE_PROJECT_USER_ROLE;

      const response = await axios.post(endpoint, { purm_id });

      const messageCode =
        response?.data?.data?.messageCode || "Operation successful";
      toast.success(messageCode);

      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating process status:", error);

      const errorMessage =
        error?.response?.data?.data?.messageCode || "An error occurred";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Process Assignment</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Process Assignment</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a assign process"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a process assign"
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ process_id: "" })}
            className="bg-gray-800 text-white px-8 py-2 font-bold rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            aria-label="Assign Project"
          >
            Assign Process
          </button>
        </div>
      </div>

      {loading ? (
        <Loader /> // Show loader while loading
      ) : projectUserRoleData.length === 0 ? (
        <NoDataFound /> // Show no data found component
      ) : (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("project_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Project Name</span>
                    {getSortIcon("project_name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("process_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Process Name</span>
                    {getSortIcon("process_name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Employee Name</span>
                    {getSortIcon("name")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("role_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Role</span>
                    {getSortIcon("role_name")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("startDate")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Start Date</span>
                    {getSortIcon("startDate")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("endDate")}
                >
                  <div className="flex  items-center gap-2">
                    <span> End Date</span>
                    {getSortIcon("endDate")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("percentage")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Percentage</span>
                    {getSortIcon("percentage")}
                  </div>
                </th>

                <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {filterProjectUserRoleData.map((projectuserrole, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {projectuserrole.project_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {projectuserrole.process_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {projectuserrole.user_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {projectuserrole.role_name}
                  </td>

                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(projectuserrole.startDate).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(projectuserrole.endDate).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {projectuserrole.percentage}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(projectuserrole)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <FaEdit className="text-indigo-500 text-xl" />
                      </button>

                      <button
                        className="text-sm px-3 py-1 rounded"
                        onClick={() =>
                          handleStatus(
                            projectuserrole.purm_id,
                            projectuserrole.is_active
                          )
                        }
                      >
                        {projectuserrole.is_active === 1 ? (
                          <FaToggleOn className="text-green-500 text-xl" />
                        ) : (
                          <FaToggleOff className="text-red-500 text-xl" />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showAddEditModal && (
        <ProjectAssignmentModal
          editingProcess={editingProcess}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleOutsideClick={handleOutsideClick}
          projectData={projectData}
          filteredProcessData={filteredProcessData}
          employeeData={employeeData}
          roleData={roleData}
        />
      )}
    </div>
  );
};

export default ProjectAssignmentDashboard;
