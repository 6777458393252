import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { projectEndPoints, userEndPoints } from "../../services/apis";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import {
  FaEdit,
  FaToggleOn,
  FaToggleOff,
  FaSort,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";
import checkRoleAccess from "../shared/CheckRoleAcess";
import Loader from "../../utils/Loader.jsx";
import NoDataFound from "../../utils/NoDataFound.jsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ProjectDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    project_id: "",
    project_name: "",
    project_manager: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Optimized filtering logic
  const filteredProjects = projectData
    .filter(
      (project) =>
        (project.project_id &&
          project.project_id
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (project.project_name &&
          project.project_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (project.project_manager &&
          project.project_manager
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (sortConfig.key === null) return 0;
      const aVal = a[sortConfig.key]?.toLowerCase() || "";
      const bVal = b[sortConfig.key]?.toLowerCase() || "";
      if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });

  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingProject(null);
  };

  const openAddEditModal = (project) => {
    setFormData({
      project_id: project.project_id || "",
      project_name: project.project_name,
      project_manager: project.project_manager,
    });
    setEditingProject(project.project_id ? true : false);
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (editingProject) {
        await axios.post(projectEndPoints.POST_UPDATE_PROJECT, {
          project_id: formData.project_id,
          project_name: formData.project_name,
          project_manager: formData.project_manager,
        });
        toast.success("Project updated successfully");
      } else {
        await axios.post(projectEndPoints.POST_CREATE_PROJECT, {
          project_name: formData.project_name,
          project_manager: formData.project_manager,
        });
        toast.success("Project added successfully");
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error("Error processing request");
    } finally {
      setIsSubmitting(false); // End loader
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProjectData = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      setProjectData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const fetchProjectManagerData = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      setEmployeeData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching Employee Data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchProjectData(projectEndPoints.GET_ALL_PROJECTS);
    fetchProjectManagerData(userEndPoints.GET_ALL_PROJECT_MANAGER);
  }, [updateUI]);

  const handleStatus = async (project_id, is_active) => {
    try {
      if (is_active === 1) {
        await axios.post(projectEndPoints.POST_DEACTIVATE_PROJECT, {
          project_id,
        });
        toast.success("Project deactivated successfully");
      } else {
        await axios.post(projectEndPoints.POST_ACTIVATE_PROJECT, {
          project_id,
        });
        toast.success("Project activated successfully");
      }
      setUpdateUI((prevState) => !prevState);
    } catch (error) {
      console.error("Error updating project status:", error);
      toast.error("Error updating project status");
    }
  };

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Projects</title>
        </Helmet>
      </HelmetProvider>
      <h1 className="text-4xl font-bold mb-4">Project Dashboard</h1>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search for a project"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
          aria-label="Search for a project"
        />

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({ project_name: "" })}
            className="bg-gray-800 text-white px-8 py-2 font-bold rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            aria-label="Add Project"
          >
            Add Project
          </button>
        </div>
      </div>

      {loading ? (
        <Loader /> // Show loader while loading
      ) : filteredProjects.length === 0 ? (
        <NoDataFound /> // Show no data found component
      ) : (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gradient-to-r from-gray-800 to-gray-700 text-white">
              <tr>
                {/* <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("project_id")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Serial Number</span>
                    {getSortIcon("project_id")}
                  </div>
                </th> */}
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("project_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Project Name </span>
                    {getSortIcon("project_name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("project_manager")}
                >
                  <div className="flex  items-center gap-2">
                    <span> Project Manager </span>
                    {getSortIcon("project_manager")}
                  </div>
                </th>
                <th className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredProjects.map((project, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  {/* <td className="border border-gray-300 p-3 text-gray-700">
                    {project.project_id}
                  </td> */}
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {project.project_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {project.name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(project)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <FaEdit className="text-indigo-500 text-xl" />
                      </button>

                      <button
                        className="text-sm px-3 py-1 rounded"
                        onClick={() =>
                          handleStatus(project.project_id, project.is_active)
                        }
                      >
                        {project.is_active === 1 ? (
                          <FaToggleOn className="text-green-500 text-xl" />
                        ) : (
                          <FaToggleOff className="text-red-500 text-xl" />
                        )}{" "}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-center m-4">
              <h1 className="text-xl text-gray-700 font-bold">
                {editingProject ? "Edit Project" : "Add Project"}
              </h1>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-bold text-gray-700">
                  Project Name <span className="text-red-600">*</span>
                </label>
                <input
                  name="project_name"
                  type="text"
                  value={formData.project_name}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter Project Name"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="project_manager"
                  className="block text-sm font-bold text-gray-700"
                >
                  Project Manager
                </label>
                <select
                  id="project_manager"
                  name="project_manager"
                  value={formData.project_manager}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                  aria-label="Project Manager"
                >
                  <option value="">Select Employee</option>
                  {employeeData.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="m-20">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <AiOutlineLoading3Quarters className="animate-spin text-xl mr-2" />
                  ) : null}
                  {editingProject ? "Update Project" : "Add Project"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDashboard;
