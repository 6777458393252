import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register chart components
ChartJS.register(ArcElement, Tooltip, Legend);

const ProcessOverviewChart = ({ totalProcesses = 13, assignedProcesses = 2 }) => {
  // Data for the Pie chart
  const data = {
    labels: ["Total Process", "Assigned Process"],
    datasets: [
      {
        data: [totalProcesses, assignedProcesses], // Data dynamically based on props
        backgroundColor: ["#E5E7EB", "#3B82F6"], // Colors: grey, blue
        hoverBackgroundColor: ["#D1D5DB", "#2563EB"], // Hover colors
      },
    ],
  };

  // Options for the Pie chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "70%", // Creates the doughnut effect
  };

  return (
    <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Process Overview</h2>
      <div className="relative w-40 h-40">
        <Pie data={data} options={options} aria-label="Process overview pie chart" />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <span className="text-2xl font-semibold">
            {`Total: ${totalProcesses}`}
          </span>
          <span className="text-lg">
            {`Assigned: ${assignedProcesses}`}
          </span>
        </div>
      </div>
      <LegendInfo totalProcesses={totalProcesses} assignedProcesses={assignedProcesses} />
    </div>
  );
};

// LegendInfo Component
const LegendInfo = ({ totalProcesses, assignedProcesses }) => (
  <ul className="mt-4 space-y-2">
    <li className="flex items-center">
      <span className="block w-3 h-3 mr-2 bg-gray-300 rounded-full"></span>
      Total Process: {totalProcesses}
    </li>
    <li className="flex items-center">
      <span className="block w-3 h-3 mr-2 bg-blue-500 rounded-full"></span>
      Assigned Process: {assignedProcesses}
    </li>
  </ul>
);

export default ProcessOverviewChart;
