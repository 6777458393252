import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  projectEndPoints,
  processEndPoints,
  taskAssignmentEndPoints,
  timesheetEndPoints,
  userEndPoints,
} from "../../services/apis";

import { HelmetProvider, Helmet } from "react-helmet-async";
import checkRoleAccess from "../shared/CheckRoleAcess";
import Loader from "../../utils/Loader.jsx";
import NoDataFound from "../../utils/NoDataFound.jsx";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimesheetDashboard = () => {
  const [projectData, setProjectData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [filteredProcessData, setFilteredProcessData] = useState([]);
  const [TaskData, setTaskData] = useState([]);
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [updateUI, setUpdateUI] = useState(false);
  const [formData, setFormData] = useState({
    task_id: "",
    project_id: "",
    process_id: "",
    effort_time: "",
    description: "",
    status: "in process",
    date: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [projectName, setProjectName] = useState(null);
  const [processName, setProcessName] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);

  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setHours(23, 59, 59, 999);

  const filteredTimesheetData = timesheetData
    .filter((task) => {
      const matchProjectName = projectName
        ? task.project_id === projectName
        : true;
      const matchProcessName = processName
        ? task.process_id === processName
        : true;
      const matchEmployeeName = employeeName
        ? task.user_id === employeeName
        : true;

      const taskDate = new Date(task.date);
      const isWithinDateRange =
        startDate && endDate
          ? taskDate >= startDate && taskDate <= adjustedEndDate
          : true;

      return (
        matchProjectName &&
        matchProcessName &&
        matchEmployeeName &&
        isWithinDateRange
      );
    })
    .sort((a, b) => {
      if (sortConfig.key === null) return 0;
      const aVal = a[sortConfig.key]?.toLowerCase() || "";
      const bVal = b[sortConfig.key]?.toLowerCase() || "";
      if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });

  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const closeModals = () => {
    setShowAddEditModal(false);
    setEditingTask(null);
  };

  const openAddEditModal = (task) => {
    const {
      task_id,
      project_id,
      process_id,
      effort_time,
      description,
      status,
      date,
    } = task;
    setFormData({
      task_id: task_id || "",
      project_id: project_id || "",
      process_id: process_id || "",
      effort_time: effort_time || "",
      description: description || "",
      status: status || "in process",
      date: date || "",
    });
    setEditingTask(task.task_id ? true : false);

    // Filter the processes and tasks when editing
    if (project_id) {
      setFilteredProcessData(
        processData.filter((process) => process.project_id === project_id)
      );
    }
    if (process_id) {
      setFilteredTaskData(
        TaskData.filter((task) => task.process_id === process_id)
      );
    }

    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "project_id") {
      const filteredProcesses = processData.filter(
        (process) => process.project_id === value
      );
      setFilteredProcessData(filteredProcesses);
    }

    if (name === "process_id") {
      const filteredTasks = TaskData.filter(
        (task) => task.process_id === value
      );
      setFilteredTaskData(filteredTasks);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const updatedFormData = { user_id: userId, ...formData };

    try {
      if (editingTask) {
        const response = await axios.post(
          taskAssignmentEndPoints.POST_UPDATE_TASK,
          updatedFormData
        );
        toast.success(response.data.data.messageCode);
      } else {
        const response = await axios.post(
          timesheetEndPoints.POST_TIMESHEET_DETAILS,
          updatedFormData
        );
        toast.success(response.data.data.messageCode);
      }
      setUpdateUI((prevState) => !prevState);
      closeModals();
    } catch (error) {
      toast.error(error.response.data.data.messageCode);
    } finally {
      setIsSubmitting(false); // End loader
    }
  };

  const handleOutsideClick = (e) => {
    const { id } = e.target;
    if (id === "modal-overlay") {
      closeModals();
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        projectEndPoints.GET_ALL_ACTIVE_PROJECTS
      );
      setProjectData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const projectNameOption = [
    ...new Set(
      projectData
        .filter((project) => project.project_id && project.project_name)
        .map((project) => `${project.project_id}:${project.project_name}`)
    ),
  ].map((item) => {
    const [id, name] = item.split(":");
    return { value: id, label: name };
  });

  const fetchProcessData = async () => {
    try {
      const response = await axios.get(
        processEndPoints.GET_ALL_ACTIVE_PROCESSES
      );
      setProcessData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };
  const processNameOption = [
    ...new Set(
      processData
        .filter((process) => process.process_id && process.process_name)
        .map((process) => `${process.process_id}:${process.process_name}`)
    ),
  ].map((item) => {
    const [id, name] = item.split(":");
    return { value: id, label: name };
  });

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(userEndPoints.GET_ALL_ACTIVE_USERS);
      setEmployeeData(response.data?.data?.data || []);

      console.log("All Users:", response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const employeeNameOption = [
    ...new Set(
      employeeData
        .filter((employee) => employee.id && employee.name)
        .map((employee) => `${employee.id}:${employee.name}`)
    ),
  ].map((item) => {
    const [id, name] = item.split(":");
    return { value: id, label: name };
  });

  const fetchTaskData = async () => {
    try {
      const response = await axios.get(
        `${taskAssignmentEndPoints.GET_TASK_ASSIGN_ASSIGNMENT_BY_ID}/${userId}`
      );
      setTaskData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchTimesheetData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(timesheetEndPoints.GET_ALL_TIMESHEETS);

      setTimesheetData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching Timesheet:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    setUserId(storedUserId);
    fetchProjectData();
    fetchProcessData();
    fetchTaskData();
    fetchTimesheetData();
    fetchEmployeeData();
  }, [updateUI, userId]);

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);
  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL - Timesheet</title>
        </Helmet>
      </HelmetProvider>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-4xl font-bold mb-4">Timesheet</h1>

        <div className="flex space-x-2">
          <button
            onClick={() => openAddEditModal({})} // Changed to open an empty modal
            className="bg-gray-800 text-white px-8 py-2 font-bold rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
            aria-label="Add Project"
          >
            + Create Timesheet
          </button>
        </div>
      </div>
      <div className="flex flex-wrap -mx-2">
        <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-6 z-10">
          <label
            htmlFor="project-select"
            className="block text-sm font-semibold text-gray-600 mb-2"
          >
            Project Name
          </label>
          <Select
            classNamePrefix="form-select"
            placeholder="Search..."
            options={projectNameOption}
            value={
              projectNameOption.find(
                (option) => option.value === projectName
              ) || null
            }
            isClearable
            onChange={(selectedOption) => {
              setProjectName(selectedOption ? selectedOption.value : null);
            }}
            className="rounded-md border border-gray-300 focus:border-blue-500 shadow-sm"
          />
        </div>

        <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-6 z-10">
          <label
            htmlFor="process-select"
            className="block text-sm font-semibold text-gray-600 mb-2"
          >
            Process Name
          </label>
          <Select
            classNamePrefix="form-select"
            placeholder="Search..."
            options={processNameOption}
            value={
              processNameOption.find(
                (option) => option.value === processName
              ) || null
            }
            isClearable
            onChange={(selectedOption) => {
              setProcessName(selectedOption ? selectedOption.value : null);
            }}
            className="rounded-md border border-gray-300 focus:border-blue-500 shadow-sm"
          />
        </div>

        <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-6 z-10">
          <label
            htmlFor="employee-select"
            className="block text-sm font-semibold text-gray-600 mb-2"
          >
            Employee Name
          </label>
          <Select
            classNamePrefix="form-select"
            placeholder="Search..."
            options={employeeNameOption}
            value={
              employeeNameOption.find(
                (option) => option.value === employeeName
              ) || null
            }
            isClearable
            onChange={(selectedOption) => {
              setEmployeeName(selectedOption ? selectedOption.value : null);
            }}
            className="rounded-md border border-gray-300 focus:border-blue-500 shadow-sm"
          />
        </div>

        <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-6 z-10">
          <label
            htmlFor="date-range"
            className="block text-sm font-semibold text-gray-600 mb-2"
          >
            Date Range
          </label>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            dateFormat="yyyy/MM/dd"
            isClearable={true}
            placeholderText="Select date range"
            className="rounded-md border border-gray-300 focus:border-blue-500 shadow-sm"
          />
        </div>
      </div>
      {loading ? (
        <Loader /> // Show loader while loading
      ) : filteredTimesheetData.length === 0 ? (
        <NoDataFound /> // Show no data found component
      ) : (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Employee Name</span>
                    {getSortIcon("name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("project_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Project Name</span>
                    {getSortIcon("project_name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("process_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Process Name</span>
                    {getSortIcon("process_name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("task_name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Task Name</span>
                    {getSortIcon("task_name")}
                  </div>
                </th>

                {/* <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("task_eta")}
                >
                  <div className="flex  items-center gap-2">
                    <span>ETA (hour)</span>
                    {getSortIcon("task_eta")}
                  </div>
                </th> */}

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("effort_time")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Effort Time</span>
                    {getSortIcon("effort_time")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("date")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Date</span>
                    {getSortIcon("date")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("status")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Status</span>
                    {getSortIcon("status")}
                  </div>
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider cursor-pointer">
                  Description
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredTimesheetData.map((task, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.project_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.process_name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.task_name}
                  </td>
                  {/* <td className="border border-gray-300 p-3 text-gray-700">
                    {task.task_eta}
                  </td> */}

                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.effort_time}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(task.date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.status}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.description}
                  </td>
                  {/* <td className="border border-gray-300 p-4 text-gray-700"> 
                    <div className="flex gap-4">
                      <button
                        onClick={() => openAddEditModal(task)}
                        className="text-sm px-3 py-1 rounded flex justify-center items-center gap-2"
                      >
                        <MdEdit /> Edit
                      </button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}{" "}
      {showAddEditModal && (
        <div
          id="modal-overlay"
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto relative">
            <button
              onClick={closeModals}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h1 className="text-2xl text-center font-semibold text-gray-800 mb-6">
              {editingTask ? "Edit Timesheet" : "Add Timesheet"}
            </h1>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Project Name
                  </label>
                  <select
                    name="project_id"
                    value={formData.project_id}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select Project</option>
                    {projectData.map((project) => (
                      <option
                        key={project.project_id}
                        value={project.project_id}
                      >
                        {project.project_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Process Name
                  </label>
                  <select
                    name="process_id"
                    value={formData.process_id}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select Process</option>
                    {filteredProcessData.map((process) => (
                      <option
                        key={process.process_id}
                        value={process.process_id}
                      >
                        {process.process_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Task Name
                  </label>
                  <select
                    name="task_id"
                    value={formData.task_id}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  >
                    <option value="">Select Task</option>
                    {filteredTaskData.map((task) => (
                      <option key={task.task_id} value={task.task_id}>
                        {task.task_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Effort Time (in hours)
                </label>
                <input
                  type="number"
                  name="effort_time"
                  value={formData.effort_time}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  min="0.1"
                  step={0.1}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Status
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                >
                  <option value="">Select Status</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <AiOutlineLoading3Quarters className="animate-spin text-xl mr-2" />
                  ) : null}
                  {editingTask ? "Update Timesheet" : "Add Timesheet"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimesheetDashboard;
