import React from "react";
import DashboardHeader from "../components/Dashboard/DashboardHeader";

import StatusOverviewChart from "../components/Dashboard/StatusOverviewChart";
import ProcessOverviewChart from "../components/Dashboard/ProcessOverviewChart";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  return (
    <>
     <HelmetProvider>
        <Helmet>
          <title>MSL- Dashboard</title>
        </Helmet>
      </HelmetProvider>
      <DashboardHeader />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 p-4">
        <div className="bg-gray-100 border border-gray-300 rounded-lg p-4">
          <StatusOverviewChart />
        </div>
        <div className="bg-gray-100 border border-gray-300 rounded-lg p-4">
          <ProcessOverviewChart />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
