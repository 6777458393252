import React from "react";

const Loader = ({ size = 16, color = "blue-500" }) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div
        className={`animate-spin rounded-full border-t-4 border-${color} border-solid`}
        style={{
          height: `${size * 4}px`,
          width: `${size * 4}px`,
          borderColor: `rgba(var(--tw-color-${color}), 0.5)`,
        }}
      ></div>
    </div>
  );
};

export default Loader;
