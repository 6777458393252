import React from "react";

const DashboardHeader = () => {
  const getCurrentGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning ☀️";
    } else if (currentHour < 18) {
      return "Good Afternoon 🌤️";
    } else {
      return "Good Evening 🌙";
    }
  };

  return (
    <div className="text-center mb-8 p-8">
      <h2 className="text-3xl font-bold">{getCurrentGreeting()}</h2>
      <p className="text-gray-700 mt-2">
        Track MSL Internal's project status, priorities, and workload. Stay
        updated on progress, key tasks, and team performance in one place.
      </p>
    </div>
  );
};

export default DashboardHeader;
