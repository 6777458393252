import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register chart components
ChartJS.register(ArcElement, Tooltip, Legend);

const StatusOverviewChart = () => {
  // Data for the Pie chart
  const data = {
    labels: ["To Do", "In Progress", "Done"],
    datasets: [
      {
        data: [2, 1, 1], // Data corresponding to To Do, In Progress, and Done
        backgroundColor: ["#E5E7EB", "#3B82F6", "#34D399"], // Colors: grey, blue, green
        hoverBackgroundColor: ["#D1D5DB", "#2563EB", "#10B981"], // Hover colors
      },
    ],
  };

  // Options for the Pie chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "70%", // Creates the doughnut effect
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white rounded shadow-md">
      <h2 className="text-lg font-semibold mb-2">Status Overview</h2>

      <div className="relative w-40 h-40">
        <Pie data={data} options={options} />
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-xl font-semibold">50% To Do</span>
        </div>
      </div>
      <ul className="mt-4 space-y-1">
        <li className="flex items-center">
          <span className="block w-3 h-3 mr-2 bg-gray-300 rounded-full"></span>
          To Do: 2
        </li>
        <li className="flex items-center">
          <span className="block w-3 h-3 mr-2 bg-blue-500 rounded-full"></span>
          In Progress: 1
        </li>
        <li className="flex items-center">
          <span className="block w-3 h-3 mr-2 bg-green-400 rounded-full"></span>
          Done: 1
        </li>
      </ul>
    </div>
  );
};

export default StatusOverviewChart;
