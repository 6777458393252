import { useState } from "react";
import Select from "react-select";

const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${d.getFullYear()}-${month}-${day}`;
};

const ProjectAssignmentModal = ({
  editingProcess,
  formData,
  handleChange,
  handleSubmit,
  handleOutsideClick,
  projectData,
  filteredProcessData,
  employeeData,
  roleData,
}) => {
  const [loading, setLoading] = useState(false);

  // Wrap handleSubmit to control the loading state
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    try {
      await handleSubmit(event); // Call the passed-in handleSubmit function
    } finally {
      setLoading(false); // Stop loading after submission
    }
  };
  // Format the date values in formData for the date input fields
  const formattedStartDate = formatDate(formData.startDate);
  const formattedEndDate = formatDate(formData.endDate);
  return (
    <div
      id="modal-overlay"
      onClick={handleOutsideClick}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-center m-4">
          <h1 className="text-2xl text-gray-700 font-bold">
            {editingProcess ? "Edit Process Assigned" : "Assign Process"}
          </h1>
        </div>
        <form className="space-y-6" onSubmit={onSubmit}>
          <fieldset className="border p-4 rounded-md">
            <legend className="text-sm font-bold text-gray-700 mb-4">
              Process Assign Details
            </legend>

            <div className="space-y-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="project_id"
                  >
                    Project Name
                  </label>
                  <select
                    id="project_id"
                    name="project_id"
                    value={formData.project_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Project Name"
                  >
                    <option value="" disabled>
                      Select Project
                    </option>
                    {projectData.map((project) => (
                      <option
                        key={project.project_id}
                        value={project.project_id}
                      >
                        {project.project_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="process_id"
                  >
                    Process Name
                  </label>
                  <select
                    id="process_id"
                    name="process_id"
                    value={formData.process_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Process Name"
                  >
                    <option value="" disabled>
                      Select Process
                    </option>
                    {filteredProcessData.map((process) => (
                      <option
                        key={process.process_id}
                        value={process.process_id}
                      >
                        {process.process_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="employee_id"
                  >
                    Employee Name
                  </label>
                  <select
                    id="user_id"
                    name="user_id"
                    value={formData.user_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Employee Name"
                  >
                    <option value="" disabled>
                      Select Employee
                    </option>
                    {employeeData.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="role_id"
                  >
                    Role Name
                  </label>
                  <select
                    id="role_id"
                    name="role_id"
                    value={formData.role_id}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Role Name"
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    {roleData
                      .filter(
                        (role) =>
                          role.role_name === "Project Manager" ||
                          role.role_name === "Team Member"
                      )
                      .map((roles) => (
                        <option key={roles.role_id} value={roles.role_id}>
                          {roles.role_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="startDate"
                  >
                    Start Date
                  </label>
                  <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    value={formattedStartDate}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Start Date"
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="endDate"
                  >
                    End Date
                  </label>
                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    value={formattedEndDate}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    aria-label="End Date"
                  />
                </div>
              </div>
              {/* Percentage Field */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label
                    className="block text-sm font-bold text-gray-700"
                    htmlFor="percentage"
                  >
                    Percentage
                  </label>
                  <input
                    id="percentage"
                    name="percentage"
                    type="number"
                    min="0"
                    max="100"
                    value={formData.percentage}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                    aria-label="Percentage"
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <div className="pt-4">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              disabled={loading} // Disable button when loading
            >
              {loading
                ? "Submitting..."
                : editingProcess
                ? "Update Assigned Process"
                : "Assign Process"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectAssignmentModal;
