const BASE_URL = process.env.REACT_APP_BASE_URL;

const LOGIN_BASE_URL = process.env.REACT_APP_LOGIN_BASE_URL;

// AUTH ENDPOINTS
export const endpoints = {
  LOGIN_API: LOGIN_BASE_URL + "/auth/login",
  LOGIN_MICROSOFT_API: LOGIN_BASE_URL + "/auth/login-microsoft",
  Sidemenu: BASE_URL + "/auth/menu",
};

//Users API methods

export const userEndPoints = {
  GET_ALL_USERS: BASE_URL + "/users/getAllUser",
  GET_ALL_ACTIVE_USERS: BASE_URL + "/users/getAllActiveUser",
  GET_ALL_PROJECT_MANAGER: BASE_URL + "/users/getAllProjectManager",
  // POST_CREATE_USER: BASE_URL + "/users/createUser",
  // POST_UPDATE_USER: BASE_URL + "/users/updateUser",
  // POST_DELETE_USER: BASE_URL + "/users/deleteUser",
};

//Project API methods

export const projectEndPoints = {
  GET_ALL_PROJECTS: BASE_URL + "/projects/getAllProject",
  GET_ALL_ACTIVE_PROJECTS: BASE_URL + "/projects/getAllActiveProject",
  GET_ALL_ACTIVE_PROJECT_NAME: BASE_URL + "/projects/getAllActiveProjectsName",
  POST_CREATE_PROJECT: BASE_URL + "/projects/createProject",
  POST_UPDATE_PROJECT: BASE_URL + "/projects/updateProject",
  POST_DELETE_PROJECT: BASE_URL + "/projects/deleteProject",
  POST_ACTIVATE_PROJECT: BASE_URL + "/projects/activateProject",
  POST_DEACTIVATE_PROJECT: BASE_URL + "/projects/deactivateProject",
};

//Process API Methods
export const processEndPoints = {
  GET_ALL_PROCESSES: BASE_URL + "/process/getAllProcess",
  GET_ALL_ACTIVE_PROCESSES: BASE_URL + "/process/getAllActiveProcess",
  POST_CREATE_PROCESS: BASE_URL + "/process/createProcess",
  POST_UPDATE_PROCESS: BASE_URL + "/process/updateProcess",
  POST_DEACTIVATE_PROCESS: BASE_URL + "/process/deactivateProcess",
  POST_ACTIVATE_PROCESS: BASE_URL + "/process/activateProcess",
};

//Technology API Methods
export const technologyEndPoints = {
  GET_ALL_TECHNOLOGIES: BASE_URL + "/technologies/getAllTechnology",
  GET_ALL_ACTIVE_TECHNOLOGIES:
    BASE_URL + "/technologies/getAllActiveTechnology",
  POST_CREATE_TECHNOLOGY: BASE_URL + "/technologies/createTechnology",
  POST_UPDATE_TECHNOLOGY: BASE_URL + "/technologies/updateTechnology",
  // POST_DELETE_TECHNOLOGY: BASE_URL + "/technologies/deleteTechnology",
  POST_ACTIVATE_TECHNOLOGY: BASE_URL + "/technologies/activateTechnology",
  POST_DEACTIVATE_TECHNOLOGY: BASE_URL + "/technologies/deactivateTechnology",
};

//Application API Methods
export const applicationEndPoints = {
  GET_ALL_APPLICATIONS: BASE_URL + "/applications/getAllApplications",
  GET_ALL_ACTIVE_APPLICATIONS:
    BASE_URL + "/applications/getAllActiveApplications",
  POST_CREATE_APPLICATIONS: BASE_URL + "/applications/createApplication",
  POST_UPDATE_APPLICATIONS: BASE_URL + "/applications/updateApplication",
};

//Transformationl-Lever API methods

export const transformationLeverEndPoints = {
  GET_ALL_TRANSFORMATIONS: BASE_URL + "/transformationLever/getTransformation",
  GET_ALL_ACTIVE_TRANSFORMATIONS:
    BASE_URL + "/transformationLever/getAllActiveTransformation",
  POST_CREATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/createTransformation",
  POST_UPDATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/updateTransformationLever",
  //   POST_DELETE_TRANSFORMATION: BASE_URL + "/transformationLever/deleteTransformationLever",
  POST_ACTIVATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/ActiveTransformationLever",
  POST_DEACTIVATE_TRANSFORMATION:
    BASE_URL + "/transformationLever/DeactiveTransformationLever",
};

//Task API methods

export const taskEndPoints = {
  GET_ALL_TASKS: BASE_URL + "/tasks/getAllTasks",
  GET_ALL_ACTIVE_TASKS: BASE_URL + "/tasks/getAllActiveTasks",
  GET_ALL_ACTIVE_TASKS_BY_PROJECTID_PROCESSID: BASE_URL + "/tasks/active/byProjectAndProcess",
  POST_CREATE_TASK: BASE_URL + "/tasks/createTask",
  POST_UPDATE_TASK: BASE_URL + "/tasks/updateTask",
};

//Asset Api
export const taskAssignmentEndPoints = {
  POST_TASK_ASSIGN_DETAILS: BASE_URL + "/tasksAssign/addAssignTask",
  GET_TASK_ASSIGN_ASSIGNMENT: BASE_URL + "/tasksAssign/getAllAssignTask",
  GET_TASK_ASSIGN_ASSIGNMENT_BY_ID: BASE_URL + "/tasksAssign/getAllAssignTask",
};

//Applications
export const timesheetEndPoints = {
  POST_TIMESHEET_DETAILS: BASE_URL + "/timesheets/createTimesheet",
  GET_ALL_TIMESHEETS: BASE_URL + "/timesheets/getAllTimesheets",
  POST_UPDATE_TIMESHEET: BASE_URL + "/timesheets/updateTimesheet",
  GET_TIMESHEET_BY_ID: BASE_URL + "/timesheets/getAllTimesheets",
};

//ProjectUser Role
export const projectuserroleEndPoints = {
  GET_ALL_PROJECT_USER_ROLE_DATA:
    BASE_URL + "/project-user-role/getAllProjectUserRole",
  POST_CREATE_PROJECT_USER_ROLE:
    BASE_URL + "/project-user-role/addProjectUserRole",
  POST_UPDATE_PROJECT_USER_ROLE:
    BASE_URL + "/project-user-role/updateProjectUserRole",
  POST_ACTIVATE_PROJECT_USER_ROLE:
    BASE_URL + "/project-user-role/activateProjectUserRole",
  POST_DEACTIVATE_PROJECT_USER_ROLE:
    BASE_URL + "/project-user-role/deactivateProjectUserRole",
};

//Stage Master

export const stageEndPoints = {
  GET_ALL_STAGES: BASE_URL + "/stages/getAllStages",
  GET_ALL_ACTIVE_STAGES: BASE_URL + "/stages/getAllActiveStages",
  POST_CREATE_STAGE: BASE_URL + "/stages/createStage",
  POST_UPDATE_STAGE: BASE_URL + "/stages/updateStage",
  POST_ACTIVATE_STAGE: BASE_URL + "/stages/activateStage",
  POST_DEACTIVATE_STAGE: BASE_URL + "/stages/deactivateStage",
};

//Function Master
export const functionEndPoints = {
  GET_ALL_FUNCTIONS: BASE_URL + "/functions/getAllFunction",
  GET_ALL_ACTIVE_FUNCTIONS: BASE_URL + "/functions/getAllActiveFunction",
  POST_CREATE_FUNCTION: BASE_URL + "/functions/createFunction",
  POST_UPDATE_FUNCTION: BASE_URL + "/functions/updateFunction",
  POST_DELETE_FUNCTION: BASE_URL + "/functions/deleteFunction",
  POST_ACTIVATE_FUNCTION: BASE_URL + "/functions/activateFunction",
  POST_DEACTIVATE_FUNCTION: BASE_URL + "/functions/deactivateFunction",
};

//Roles Master
export const roleEndPoints = {
  GET_ALL_ROLE: BASE_URL + "/roles/getAllRoles",
  GET_ALL_ACTIVE_ROLES: BASE_URL + "/roles/getAllActiveRoles",
};
